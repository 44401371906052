@import "../../assets/scss/variables";

@media screen and (max-width: 1599px) and (min-height: 831px) {
  .dashboard.inventories-container {
    height: calc(100vh - 170px) !important;
  }
}

.inventories-container {
  display: unset !important;
  max-height: 980px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  margin: 0px -15px;
  border-radius: 10px;
  background-color: white;

  .divider {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 10px;
    .navbtns {
      width: 300px;
      li {
        border-bottom: none;
      }
    }
  }
  .leftcontent-inventories {
    .card-navbtns {
      /* border-bottom: 1px solid #e0e0e0; */
      .navbtns {
        margin-bottom: -1px;
      }
    }
  }
  .rightcontent {
    .card {
      height: 100%;
      background: $fullsoon;
      border-radius: 10px;
      .card-body {
        .fltr-heading {
          display: flex;
          justify-content: space-between;
          margin-bottom: 13px;
          label {
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
            display: flex;
            align-items: center;
          }
          button {
            background: transparent;
            border: 0;
            color: #fff;
            border-bottom: 1px solid #fff;
            padding: 0px 3px;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: #f3f4fb;
          }
        }

        .datepicker-wrapper {
          span {
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
            display: flex;
            align-items: center;
            color: #f3f4fb;
          }
          .datepicker-wrapper-img {
            display: flex;
            justify-content: flex-end;
          }
          img {
            cursor: pointer;
          }
          .date-picker {
            background: transparent;
            border: 0;
            color: #fff;
            font-weight: bold;
            width: 100%;
            outline: none;
          }
        }

        .react-datepicker-wrapper {
          width: 60%;
        }
        .hr-separator {
          height: 0.5px;
          margin: 0.5rem 0;
          color: #f3f4fb;
        }
        label {
          cursor: pointer;
          &:hover {
            color: #fff !important;
          }
        }
        color: #fff;
        h1 {
          font-weight: bold;
          font-size: 21px;
          line-height: 29px;
        }
        .action-btns {
          bottom: 20px;
          display: flex;
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          button {
            background: #f1f3f4;
            border-radius: 5px;
            width: 221px;
            height: 40px;
            width: 102px;
            height: 40px;
            border: 0;
            font-size: 16px;
            font-weight: bold;
            &.close-btn {
              color: #8b8f94;
            }
            &.apply-btn {
              color: #6353ea;
            }
          }
        }
        .hide-filter {
          display: flex;
          justify-content: end;
          font-weight: bold;
          font-size: 14px;
          line-height: 19px;
          margin-top: 5px;
          text-decoration: underline;
          cursor: pointer;
          img{
            margin-left: 5px;
          }
        }
      }
      .checkbox {
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
        input {
          display: none; /* Hide the default checkbox */
        }
        span {
          height: 15px;
          width: 15px;
          display: inline-block;
          position: relative;
          border: 1px solid #ffffff;
          box-sizing: border-box;
          border-radius: 2px;
        }
        [type="checkbox"]:checked + span:before {
          content: "\2714";
          position: absolute;
          top: -2px;
          left: 1.5px;
          font-size: 14px;
        }
      }
    }
  }

  .card-pie-chart {
    display: flex;
    flex-direction: row;
    min-height: 100px;
    height: inherit;
    justify-content: space-between;
    max-height: calc(100% - 50px);
  }

  .after-divider-container {
    height: calc(100% - 110px);
  }

  .first-card {
    /* height: 35% !important; */
    height: 46% !important;
  }
  .main-container-resturants {
    .second-card {
      height: 50% !important;
    }
    .place-container {
      background: #fff;
      color: #000;
      font-size: 36px;
      line-height: 100px;
      margin: 5px;
      padding: 5%;
      position: relative;
      border-radius: 5px;
      height: 76px;
      font-family: Nunito Sans;
      font-style: normal;
      .parent {
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .place-name {
        font-weight: bold;
        font-size: 14px !important;
        line-height: 19px !important;
      }

      .address {
        font-weight: 600;
        font-size: 12px !important;
        line-height: 16px !important;
      }

      .resturant-icon {
        width: 32px;
        height: 32px;
      }
    }

    .place-container-active {
      background: rgba(99, 83, 234, 1);
      color: #fff;
    }
  }

  .main-container-competitiveset {
    .pie-sales-navbtns {
      display: none;
    }
    .pie-container {
      margin-bottom: 20px;
      height: 55% !important;
      .highcharts-title {
        font-size: 15px !important;
      }
    }
  }

  @media screen and (min-width: 1600px) {
    .after-divider-container {
      height: calc(100% - 51px);
    }
  }

  @media screen and (min-width: 1600px) and (min-height: 850px) and (max-height: 1000px) {
    .main-container-competitiveset {
      .pie-container {
        height: 50% !important;
      }
    }
  }

  @media screen and (max-width: 1599px) {
    .show-below-800 {
      height: calc(100% - 270px) !important;
    }
    .show-above-800 {
      height: calc(100% - 255px) !important;
    }
    .after-divider-container {
      height: calc(100% - 50px) !important;
    }
  }

  @media screen and (min-width: 1600px) and (min-height: 851px) {
    .show-above-800 {
      height: calc(100% - 240px) !important;
    }
  }

  @media screen and (min-width: 1600px) and (max-height: 850px) {
    .show-below-800 {
      height: calc(100% - 300px) !important;
    }
  }

  @media screen and (max-height: 850px) {
    .main-container-competitiveset {
      .pie-sales-navbtns {
        display: block;
      }
      .show-below-800 {
        display: block;
      }
      .show-above-800 {
        display: none;
      }
      .pie-container {
        height: 100% !important;
      }
      .card-pie-chart {
        max-height: calc(100% - 50px);
      }
    }
  }

  @media screen and (max-height: 640px) {
    .main-container-competitiveset {
      .show-below-800 {
        height: calc(100% - 140px) !important;
      }
      .card-pie-chart {
        max-height: calc(100% - 10px);
      }

      .pie-container {
        margin-bottom: 20px;
        height: 55% !important;
        .highcharts-title {
          font-size: 10px !important;
          text-overflow: ellipsis;
        }
      }
    }
  }

  @media screen and (min-height: 851px) {
    .main-container-competitiveset {
      .show-above-800 {
        display: block;
      }
      .show-below-800 {
        display: none;
      }
    }
  }

  .main-container-competitiveset {
    .top-flop-container {
      .top-flop {
        .top-flop-item {
          @media screen and (max-height: 785px) {
            &:nth-last-child(2) {
              display: flex !important;
            }
          }
          @media screen and (max-height: 830px) {
            &:nth-last-child(1) {
              display: flex !important;
            }
          }
          @media screen and (min-width: 1921px) and (max-height: 821px) {
            &:nth-last-child(2) {
              display: flex !important;
            }
          }
          @media screen and (min-width: 1921px) and (max-height: 970px) {
            &:nth-last-child(1) {
              display: flex !important;
            }
          }
          @media screen and (min-width: 1600px) and (min-height: 850px) and (max-height: 920px) {
            &:nth-last-child(1) {
              display: none !important;
            }
          }
        }
      }
    }
  }
}

input[type="range"] {
  -webkit-appearance: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 0px;
  background: transparent;
  border: none;
  border-radius: 3px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 5px;
  width: 15%;
  border-radius: 0%;
  background: #bdbdbd;
  margin-top: 0px;
  border-radius: 5px;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: transparent;
}
.carousel-container-with-scrollbar {
  padding-bottom: 30px;
  overflow: visible !important;
}
.custom-slider {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
}
.custom-slider__input {
  width: 50%;
}
.slider-image-item {
  padding: 0px 0 10px 20px;
}

.carousel-container-with-scrollbar {
  padding-bottom: 0;
}

.w-85 {
  width: 85% !important;
  overflow: hidden;
}

@media(max-width: 430px){
// @media(min-width: 576px){
  .inventories-container{
    .divider{
      .navbtns{
        width: 100% !important;
      }
    }
  }
  .after-divider-container{
    padding: 0px 4px !important;
  }
}

.card_wrapper{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  @media(min-width: 1800px){
    gap: 40px;
  }

  .fruit_card{ 
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px -5px 8px 0px;
    // @media(min-width: 576px){
    @media(max-width: 374px){
      width: calc(50% - 10px);
      .card_title{
        font-size: 14px !important;
        font-weight: 700 !important;
        margin-bottom: 0px !important;
      }
      .counter_wrapper{
        height: 20px !important;
      }
      .counter_btn{
        width: 14px !important;
        height: 15px !important;
      }
      .counter_value{
        height: 20px !important;
        font-size: 14px !important;
        font-weight: 700 !important;
      }
      .counter_label{
        margin-bottom: 0px !important;
        font-size: 13px !important;
      }
      .card_header{
        padding: 10px 0px !important;
      }
      .card_bio {
        font-size: 14px !important;
      }
      img{
        width: 60px !important;
        height: 60px !important;
      }
      .inventories-container{
        .divider{
          .navbtns{
            width: 100% !important;
          }
        }
      }
    }
    @media(min-width: 992px){
      width: calc(33.33% - 20px);
    }
    @media(min-width: 1200px){
      width: calc(25% - 20px);
    }
    @media(min-width: 1800px){
      width: calc(25% - 40px);
    }
  }
  .card_header{
    background: #fff;
    padding: 20px 15px;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: #000;
    .card_img{
      margin-bottom: 10px;
    }
    img{
      width: 100px;
      height: 100px;
    }
    .card_title{
      display: block;
      width: 100%;
      margin-bottom: 10px;
      text-align: center;
      font-size: 24px;
      line-height: 28px;
      font-weight: bold;
      white-space: nowrap; 
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      padding: 0px 7px;
    }
    .card_bio{
      display: block;
      width: 100%;
      text-align: center;
      font-size: 19px;
      line-height: 24px;
      
    }
  }
  .counter{
    padding: 6px 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

  }
  .counter_label{
    display: block;
    text-align: center;
    margin-bottom: 10px;
    color: #873CFC;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
  }
  .counter_holder{
    -webkit-box-shadow: 0 4px 9px -4px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 4px 9px -4px rgba(0, 0, 0, 0.4);
    box-shadow: 0 4px 9px -4px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    padding: 11px 0;
    background: transparent;

  }
  .counter_wrapper{
    max-width: 150px;
    margin: auto;
    background: #fff;
    border-radius: 41px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 6px;
    box-shadow: 0px 1.4px 3px 0px #00000040;
  }
  .counter_btn{
    width: 23.5px;
    height: 23.5px;
    border-radius: 50%;
    background: linear-gradient(90deg, rgba(130,50,255,1) 0%, rgba(164,119,234,1) 100%);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    line-height: 24px;
    font-weight: 900;
    flex-shrink: 0;
    border: 0;
    cursor: pointer !important;
    
  }
  .counter_value{
    flex-grow: 1;
    text-align: center;
    padding: 5px;
    border: none;
    width: 85px;
    color: #873CFC;
    font-weight: 800;
    font-size: 20px;
  }
}

.fruit_card {
  position: relative;
}

.card_checkbox {
  position: absolute;
  top: 10px;
  left: 10px;
}

.fruit_card.selected {
  border: 2px solid blue;
}

.provider-container {
  margin: 30px 0;

  .provider-header {
    display: flex;
    align-items: center;
    position: relative;
    margin: 20px 0px;

    .provider-circle {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #6353ea;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-weight: bold;
      margin-right: 10px;
      position: relative;
      z-index: 2;
    }

    .provider-name {
      margin-bottom: 20px;
      color: #6353ea;
    }

    .provider-line {
      width: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      z-index: 1;
      height: 2px;
      border-bottom: 1px solid #e0e0e0;
    }
  }
}

.button-wrapper {
  display: flex;
  justify-content: space-between;

  .button {
    width: 48%;
    border: none !important;

    &.cancel {
      background-color: #94A3B8;
      margin-right: 20px;

      &:hover,
      &:active,
      &:focus,
      &:visited {
        background-color: #94A3B8;
      }
    }

    &.save {
      background-color: #6353ea;
    }
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.disabled .counter_btn {
  background-color: #ddd !important;
  color: #888 !important;
  cursor: not-allowed;
}
